const phone = {}

phone.splitPhone = function(phone) {
    if (typeof phone === 'string') {
        phone = phone.replace(/^"|"$/g, '').replace(/^'|'$/g, '')
        try {
            let phones = []
            if (phone.includes(',')) {
                phones = phone.split(',')
                if (phones[1].trim().length >= 7) {
                    let prefix = phones[0].includes(')') ? phones[0].split(')')[0] + ')' : ''
                    for (let i = 0; i <= (phones.length - 1); i++) {
                        let phone = phones[i].trim()
                        if (phone.match(/[a-zA-Z|а-яА-Я]/i)) phones[i] = { value: phone, isPhone: false }
                        else if (phone.includes(')')) phones[i] = { value: phone, isPhone: true }
                        else if (prefix) phones[i] = { value: (prefix + phone).trim(), isPhone: true }
                        else phones[i] = { value: phone, isPhone: true }
                    }
                } else {
                    phones = [{ value: phones[0], isPhone: false }]
                }
            } else {
                phones = [{ value: phone, isPhone: true }]
            }
            return phones
        } catch (e) {
            console.log(e)
            return [{ value: phone, isPhone: true }]
        }
    } else {
        return phone
    }
}

const _toNormalPhone = function(num) {
    let digits = num?.replace(/\D/g, '') || ''

    if (digits.length < 10) return num || ''

    if (digits.length === 11 && digits[0] === '7') {
        digits = '8' + digits.substring(1)
    } else if (digits.length === 10) {
        digits = '8' + digits
    }
    
    return digits.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 ($2) $3 $4 $5")
}

phone.toNormalPhone = function(num) {
    if (num.includes(',')) {
        return num.split(',').map(_toNormalPhone).join(', ')
    }
    return _toNormalPhone(num)
}

phone.reformatPhone = function(phone = null) {
    if(!phone) return ''
    
    phone = phone.trimStart().trimEnd()
    const index = 0
    if(phone[index] == 8) phone = phone.substring(0, index) + '8' + phone.substring(index + 1);
    if(phone.includes('800') && phone.includes('+7')) {
        phone = phone.replace(/\+7\s?800/g, '8 800')
        phone = phone.replace(/\+7\s?\(800\)/g, '8 (800)')
    }

    return phone
}
export default phone