var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[(_vm.blocks)?_c('section',{staticClass:"container"},[_c('Crumbs',{attrs:{"data":_vm.crumbs}}),_c('TopBlock',{attrs:{"data":Object.assign({}, _vm.blocks[0], {list: _vm.blocks[0].content2}, _vm.background)}})],1):_vm._e(),(_vm.blocks)?_c('section',{staticClass:"main__box bg--gray"},[_c('div',{staticClass:"container"},[_c('AdvantagesBox',{attrs:{"data":{ first: _vm.blocks[1], second: _vm.blocks[2], third: _vm.blocks[3] }}})],1)]):_vm._e(),(_vm.blocks)?_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('h2',{staticClass:"main__title"},[_vm._v(" Процесс регистрации ")]),_c('Steps',{attrs:{"data":{
						class: 'steps--stage steps--hover',
						list: [
							Object.assign({}, _vm.blocks[4],
								{image: require('@/assets/images/icons/icon_steps-email.svg')}),
							Object.assign({}, _vm.blocks[5],
								{image: require('@/assets/images/icons/icon_steps-edit.svg')}),
							Object.assign({}, _vm.blocks[6],
								{image: require('@/assets/images/icons/icon_steps-save.svg')}),
							Object.assign({}, _vm.blocks[7],
								{image: require('@/assets/images/icons/icon_steps-settings.svg')}) ],
					}}})],1)]):_vm._e(),_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('h2',{staticClass:"main__title"},[_vm._v(" Ключевые участники проекта ")]),_c('ul',{staticClass:"grid"},_vm._l((_vm.keyPartners.slice(0, 12)),function(item,index){return _c('li',{key:index,staticClass:"grid__item"},[_c('div',{staticClass:"grid__link grid__link--no-hover"},[_c('div',{staticClass:"grid__logo-box"},[_c('img',{staticClass:"grid__logo",attrs:{"src":item.logo,"alt":item.name}})]),_c('div',{staticClass:"grid__content"},[_c('h3',{staticClass:"grid__title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"grid__text"},[_vm._v(" "+_vm._s(item.descriptionShort)+" ")]),(item.phone)?_c('a',{staticClass:"link grid__text text-normal",attrs:{"href":'tel:' + item.phone}},[_c('span',{staticClass:"icon icon--phone"}),_vm._v(" "+_vm._s(_vm.reformatPhone(_vm.toNormalPhone(item.phone)))+" ")]):_vm._e(),(item.webSiteURL)?_c('a',{staticClass:"link grid__text text-normal",attrs:{"href":item.webSiteURL,"target":"_blank"}},[(item.strUrl)?_c('span',{staticClass:"icon icon--globe"}):_vm._e(),_vm._v(" "+_vm._s(_vm.clearWwwHttpFromUrl(item.strUrl))+" ")]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"last"},[_c('section',{staticClass:"main__box main__box--big main__box--bg-circles-dark bg--gray"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex--center"},[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'ContactsPartner' }}},[_vm._v(" Связаться с нами ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }