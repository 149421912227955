<template>
	<ul class="steps" :class="data.class" v-if="data">
		<li class="steps__item" v-for="(step, index) in data.list" :key="index">
			<img :src="step.image" :alt="step.title" class="steps__icon" />
			<h3 class="steps__title">{{ step.title }}</h3>
			<div
				class="steps__text editor"
				v-if="step.description"
				v-html="step.description"
			></div>
			<div
				class="steps__hover-text editor"
				v-if="step.content"
				v-html="step.content"
			></div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Steps',

	props: {
		data: {
			type: Object,
		},
	},
}
</script>
