<template>
	<main class="main">
		<section class="container" v-if="blocks">
      <Crumbs :data="crumbs"></Crumbs>
			<TopBlock
				:data="{ ...blocks[0], list: blocks[0].content2, ...background }"
			></TopBlock>
		</section>

		<section class="main__box bg--gray" v-if="blocks">
			<div class="container">
				<AdvantagesBox
					:data="{ first: blocks[1], second: blocks[2], third: blocks[3] }"
				></AdvantagesBox>
			</div>
		</section>

		<section class="container" v-if="blocks">
			<div class="main__box">
				<h2 class="main__title">
					Процесс регистрации
				</h2>
				<Steps
					:data="{
						class: 'steps--stage steps--hover',
						list: [
							{
								...blocks[4],
								image: require('@/assets/images/icons/icon_steps-email.svg'),
							},
							{
								...blocks[5],
								image: require('@/assets/images/icons/icon_steps-edit.svg'),
							},
							{
								...blocks[6],
								image: require('@/assets/images/icons/icon_steps-save.svg'),
							},
							{
								...blocks[7],
								image: require('@/assets/images/icons/icon_steps-settings.svg'),
							},
						],
					}"
				></Steps>
			</div>
		</section>

		<section class="container">
			<div class="main__box">
				<h2 class="main__title">
					Ключевые участники проекта
				</h2>
				<ul class="grid">
					<li
						class="grid__item"
						v-for="(item, index) in keyPartners.slice(0, 12)"
						:key="index"
					>
						<div class="grid__link grid__link--no-hover">
							<div class="grid__logo-box">
								<img :src="item.logo" :alt="item.name" class="grid__logo" />
							</div>
							<div class="grid__content">
								<h3 class="grid__title">
									{{ item.name }}
								</h3>
								<p class="grid__text">
									{{ item.descriptionShort }}
								</p>
								<a
									:href="'tel:' + item.phone"
									class="link grid__text text-normal"
									v-if="item.phone"
								>
									<span class="icon icon--phone"></span>
									{{ reformatPhone(toNormalPhone(item.phone)) }}

								</a>
								<a
									:href="item.webSiteURL"
									class="link grid__text text-normal"
									v-if="item.webSiteURL"
									target="_blank"
								>
									<span v-if="item.strUrl" class="icon icon--globe"></span>
									{{ clearWwwHttpFromUrl(item.strUrl) }}
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>

		<div class="last">
			<section
				class="main__box main__box--big main__box--bg-circles-dark bg--gray"
			>
				<div class="container">
					<div class="flex flex--center">
						<router-link class="button" :to="{ name: 'ContactsPartner' }">
							Связаться с нами
						</router-link>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import TopBlock from '@/components/TopBlock.vue'
import AdvantagesBox from '@/components/PartnerAdvantages.vue'
import Steps from '@/components/Steps.vue'
import Crumbs from '@/components/Crumbs'
import phone from '@/utils/phone'
import url from '@/utils/url'

export default {
	name: 'JoiningProcedure',

	components: {
		TopBlock,
		AdvantagesBox,
		Steps,
		Crumbs,
	},

	data() {
		return {
			background: {
				animationBg: require('@/assets/images/background/bg_top-block-partner.svg'),
				animationFront: require('@/assets/images/icons/icon_partner-joining.svg'),
				animationClassBg: 'animation-pattern',
				animationClassFront: 'animation-icon',
				animationClassBox: 'main__animation-box--partner',
			},
			crumbs: null,
		}
	},

	methods: {
		showModalFeedback() {
			this.$store.dispatch('GET_MODAL', { type: 2 })
		},
		toNormalPhone(p){
			return phone.toNormalPhone(p)
		},
		clearWwwHttpFromUrl(u){
			return url.clearWwwHttpFromUrl(u)
		},
		reformatPhone(p){
			return phone.reformatPhone(p)
		}
	},

	computed: {
		keyPartners() {
			const keyPartners = this.$store.getters.KEY_PARTNERS || []
			keyPartners.filter(kp => kp.webSiteURL).forEach(partner => {
				let url = partner.webSiteURL
				const urlLength = url.length
				const lastChar = url[urlLength - 1]
				if (lastChar == '/') {
					url = url.slice(0, -1)
				}
				partner.strUrl = url.split('//')[1]
			})

			return keyPartners
		},

		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		const self = this
		this.$nextTick(() => {
			self.$store.dispatch('GET_KEY_PARTNERS')
			self.$store.dispatch('GET_BLOCKS', {
				page: self.$route.fullPath,
				block: self.$options.name,
			})

			self.crumbs = [
			{
				link: 'PartnerHome',
				text: 'Главная',
			},
			{
				link: null,
				text: 'Порядок присоединения',
			},
			]
		})
	},
}
</script>
<style>
@media (max-width: 420px){
	.main__box {
		padding: 20px 0;
		padding-bottom: 0;
		padding-bottom: 15px!important;
	}
}
</style>